<template>

  <div v-if="isVisible">

    <div class="fixture-border">

      <div v-show="!isLive" class="row no-margin" style="line-height: 1.2em; margin-top: 2px">

        <div class="col-8" style="font-size: 0.8em">
          {{ country_name }} / <span class="text-capitalize"> {{ shortenName(fixture.tournament,25) }} </span>
        </div>

        <div class="col-4 text-right bold " style="font-size: 0.8em;">
          <span>{{ formatMatchDate(fixture.date) }} </span>
        </div>

        <div v-if="upcoming" class="col-4 text-right bold orange-txt" style="font-size: 0.8em;">
          <span>Starts In {{to_start}}</span>
        </div>

      </div>

      <div class="row no-margin">

        <div class="col-6">

          <router-link style="color: var(--black); display: block;" :to="{ name: 'match', params: { game_id: fixture.game_id === undefined ? 0 : fixture.game_id, name: toURL(fixture.name), status: live ? 'live' : 'prematch' }}">

            <span style="font-size: var(--font-medium); text-transform: capitalize" class="home-away bold">
              <span v-show="isLive" class="red-txt">{{ fixture.home_score }} </span>
              {{ shortenName(home_team,15) }}
            </span>

            <span style="font-size: var(--font-medium); text-transform: capitalize" class="home-away bold">
                <span v-show="isLive" class="red-txt">{{ fixture.away_score }}</span>
                {{ shortenName(away_team,15) }}
            </span>

            <div v-if="isLive" class="red-txt text-uppercase bold match-status-text">
                {{fixture.event_status}} . {{ fixture.match_status }} . {{fixture.event_time}}'
            </div>
            <div v-else class="games-subheader" >
              <span style="margin-right: 1em">#{{ fixture.game_id }}</span>
              <span>+{{ fixture.total_markets }}</span>
            </div>

          </router-link>

        </div>

        <div class="col-6">

          <div v-if="isLive" class="row" style="margin-right: .1em;">

            <div
                v-for="o in fixture.highlight_market.outcomes"
                v-bind:key="getKey(o.odd_id)"
                v-bind:id="o.odd_id"
                class="col text-center bold"
                v-bind:class="getClasses(o)"
                style="font-size: .8rem">

              <Odd
                  v-bind:sport_id="fixture.sport_id"
                  v-bind:match_id="fixture.match_id"
                  v-bind:market_name="o.market_name"
                  v-bind:home_team="home_team"
                  v-bind:away_team="away_team"
                  v-bind:market_id="fixture.highlight_market.market_id"
                  v-bind:outcome_id="o.outcome_id"
                  v-bind:specifier="fixture.highlight_market.specifier"
                  v-bind:active="o.active"
                  v-bind:status="fixture.highlight_market.status"
                  v-bind:odds="o.odds"
                  v-bind:producer_id="fixture.producer_id"
                  v-bind:producer_status="fixture.producer_status"
                  v-bind:previous_odds="o.previous_odds"
                  v-bind:alias="o.alias"
                  v-bind:probability="o.probability"
                  v-bind:pick="o"/>
            </div>
          </div>

          <div v-else class="row" style="margin-right: .1em;">
            <div
                v-for="o in fixture.highlight_market.outcomes"
                v-bind:key="getKey(o.odd_id)"
                v-bind:id="o.odd_id"
                class="col text-center bold"
                v-bind:class="getClasses(o)"
                style="font-size: .8rem;line-height: 1.6em;">

              <Odd
                  v-bind:sport_id="fixture.sport_id"
                  v-bind:match_id="fixture.match_id"
                  v-bind:market_name="o.market_name"
                  v-bind:home_team="home_team"
                  v-bind:away_team="away_team"
                  v-bind:market_id="fixture.highlight_market.market_id"
                  v-bind:outcome_id="o.outcome_id"
                  v-bind:specifier="fixture.highlight_market.specifier"
                  v-bind:active="o.active"
                  v-bind:status="fixture.highlight_market.status"
                  v-bind:odds="o.odds"
                  v-bind:probability="o.probability"
                  v-bind:producer_id="fixture.producer_id"
                  v-bind:producer_status="fixture.producer_status"
                  v-bind:previous_odds="o.previous_odds"
                  v-bind:alias="o.alias"
                  v-bind:pick="o"/>

            </div>

            <div>
                  <a :href="'https://s5.sir.sportradar.com/fasobet/en/match/'+fixture.match_id" target="_blank">
                    <img style="height: 0.8em;position: relative;top: 1em;" src="	/assets/icons/stats.svg">
                  </a>
              </div>
          </div>

        </div>

      </div>

    </div>

    <div class="space-1"></div>

  </div>

</template>

<script>

import Odd from './Odd'

export default {
  components: {
    Odd
  },
  name: "fixture",
  props: {
    fixture: {
      required: true
    },
    live: {
      required: false,
      default: false
    },
  },
  computed: {
    event_time: function () {

      var minutes = parseInt(this.seconds / 60);
      //var seconds = this.seconds % 60;

      var min = minutes;

      if(minutes < 10 ) {

        min = "0"+min;
      }

      return min+"'";

    },
    sport_name: function () {

      return this.getSportName(this.fixture.sport_id)
    },
    country_name: function () {

      return this.fixture.country
    },
    home_team: function () {

      return this.getHomeCompetitorName(this.fixture.name)
    },
    away_team: function () {

      return this.getAwayCompetitorName(this.fixture.name)
    },
    sportIcon: function () {

      return this.getSportIcon(this.fixture.sport_id);

    },
    sportAlt: function () {
      switch (this.fixture.sport_id) {

        case 1:
          return "Soccer Icon";

        case 2:
          return "basketball icon";

        case 4:
          return "hockey icon";

        case 5:
          return "tennis icon";

        case 22:
          return "/assets/icons/dart.svg";

        case 20:
          return "/assets/icons/tabletennis.svg";

        case 6:
          return "/assets/icons/handball.svg";

        case 12:
          return "/assets/icons/rugby.svg";

        case 10:
          return "/assets/icons/boxing.svg";

        case 31:
          return "/assets/icons/badminton.svg";

        case 3:
          return "/assets/icons/baseball.svg";
      }
      return ""
    },
  },
  methods: {
    isVisible: function (){


      return this.fixture !== undefined && this.fixture !== null && this.fixture.highlight_market !== undefined && this.fixture.highlight_market !== null

    },

    isLocked: function(o){

      if(parseInt(o.producer_id) === 1 ) {

        return  parseInt(o.status) > 0 || parseInt(o.active) === 0 || parseInt(o.producer_status) === 0;

      } else {

        return  parseInt(o.status) > 0 || parseInt(o.active) === 0;

      }
    },

    getKey: function(index) {

      var prefix = "odds-"+index;
      return Math.random().toString(10).replace('0.', 'odd-id-'+prefix + '-');

    },

    getV: function(x){

      return x;

    },
    getHomeCompetitorName: function (matchName) {

      if (matchName === undefined) {

        return "";
      }

      return matchName.split('vs.')[0];
    },
    getAwayCompetitorName: function (matchName) {

      if (matchName === undefined) {

        return "";
      }
      return matchName.split('vs.')[1];
    },
    getClasses: function (o) {

      if(this.fixture === undefined || this.fixture.highlight_market === undefined ) {

        return ""

      }

      //var st = parseInt(o.active) === 0 || parseInt(o.status) !== 0 ? 'btn-odd' : 'btn-odd';

      return "btn-odd small-txt mr-1 mt-1" +
          " match-" + this.fixture.match_id +
          " producer-" + o.producer_id +
          " match-" + this.fixture.match_id +
          " market-" + this.fixture.highlight_market.market_id +
          " outcome-" + o.outcome_id +
          " specifier-" + this.fixture.highlight_market.specifier;
    },
    matchURL: function () {

      var game_id = this.fixture.game_id;
      var name = this.toURL(this.fixture.name);

      var url = "/match/" + game_id + "/" + name;
      return url;

    },
    toURL: function (name) {

      if (name === undefined) {

        return "-";
      }

      return name.replace(/[^a-z0-9+]+/gi, '-');

    },
    getOddDirectionClass: function (outcome) {

      if(parseInt(outcome.active) === 0 || parseInt(outcome.status) != 0) {

        return '';
      }

      if(parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds) ) {

        return 'arrow green up';

      }

      return 'arrow red down';

    }
  },
  mounted: function() {

    // split to get event time as seconds
    if(this.fixture.event_time.length > 0 ) {

      var p = this.fixture.event_time.split(':');
      var minutes = p[0];
      var seconds = p[1];
      seconds = parseInt(seconds);
      seconds = seconds + parseInt(minutes) * 60;
      this.seconds = seconds;
    }

    this.isLive = parseInt(this.producer_id) === 1 || parseInt(this.producer_id) === 4

    if(this.fixture.match_status === 'NotStarted' ) {

      this.upcoming = true;
      this.isLive = false;
      this.to_start = this.timeToStart(this.fixture.date);

    }

  },
  data: function() {
    return {
      color: '',
      seconds: 0,
      to_start: '',
      formattedFixture: '',
      upcoming: '',
      isLive: '',
      odds: '',
      status: '',
      active:''
    }
  }
}

</script>